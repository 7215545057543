import "core-js/modules/es.function.name.js";
import { defineComponent, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { pmsToken, memToken } from '../api/index';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var store = useStore();
    var pathName = computed(function () {
      return router.currentRoute.value.name;
    });
    onMounted(function () {
      if (pathName.value !== 'Callback' && pathName.value !== 'OauthCallback' && pmsToken) {
        store.dispatch('account/apiUser').then(function () {
          if (memToken) {
            store.dispatch('user/userLevel');
          }
        });
      }
    });
  }
});